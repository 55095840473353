import Hero from "./Hero"
import Section2 from "./Section2"
import Section3 from "./Section3"
import Section4 from "./Section4"

function index() {
  return (
    <div className="bg-[#F0F0F0]">
      <Hero />
      <Section2 />
      <Section3 />
      <Section4 />
    </div>
  )
}

export default index