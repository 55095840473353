import Faqs from '../components/Faqs';
import Layout from '../components/Layout'

function privacy() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <Faqs />
      </Layout>
    </div>
  )
}

export default privacy;