import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom'

// import './index.css';
import "@reach/dialog/styles.css"
import "./styles/globals.css"
import "./styles/landingpage.css"
// import App from './App';
import reportWebVitals from './reportWebVitals';
import Home from './pages/home'
import Privacy from './pages/privacy'
import Risk from './pages/risk'
import Teams from './pages/teams';
import Faqs from './pages/faqs';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: '/risk-disclaimer',
    element: <Risk />
  },
  {
    path: '/teams',
    element: <Teams />
  },
  {
    path: '/faqs',
    element: <Faqs />
  },
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
    {/* <App /> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
