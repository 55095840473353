import Layout from '../components/Layout'
import PrivacyPolicy from '../components/PrivacyPolicy'

function privacy() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <PrivacyPolicy />
      </Layout>
    </div>
  )
}

export default privacy;