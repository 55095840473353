const content = [
  {
    question: '1. What is the minimum deposit amount on Dcopy?',
    answer: 'You can start investing with a minimum deposit of $50.'
  },
  {
    question: '2. How to deposit to Dcoppy',
    answer: (
      <div className="">
        <p>Funding your account is quick and simple by 4 steps below:</p>
        <ul className="list-decimal px-8">
          <li>
            Click connect wallet, choose the wallet you wanna use. Please switch to the BNB network on your wallet to complete this step.
          </li>
          <li>Click Login then confirm on your wallet</li>
          <li>On the left menu bar, Click on button [Deposit Fund]</li>
          <li>Choose token then input investment amount you wanna deposit</li>
          <li>Click Deposit then confirm the transaction on your wallet</li>
        </ul>
      </div>
    )
  },
  {
    question: '3. How to withdraw from Dcoppy',
    answer: (
      <div className="">
        <p>Users can withdraw fund from Dcopy anytime. Please follow the step-by-step guide below:</p>
        <ul className="list-decimal px-8">
          <li>On the left menu bar, Click on [Withdraw]</li>
          <li>Press button Withdraw then enter the amount you wanna take back</li>
          <li>Click Withdraw then wait for about 24 hours that Dcopy can check your order and approve it.</li>
        </ul>
      </div>
    )
  },
  {
    question: '4. What is the minimum withdrawal amount?',
    answer: 'The minimum amount you can withdraw from your account is $30. And of course, you have pay no fee for withdrawing'
  },
  {
    question: '5. When will I receive my withdrawal?',
    answer: (
      <div>
        <p>Normally, you will receive your funds after 24h from the time you request the withdrawal.</p>
        <p className="italic text-sm px-4">
          * For various reasons that include security procedure requirements, some withdrawals may be only processed on the next business
          day.
        </p>
      </div>
    )
  },
  {
    question: '6. Do I need to pay fee for deposit/withdrawal?',
    answer: 'When you perform any transactions on Dcopy, you no need to pay any fee. '
  }
]

function FaqDepAndWit() {
  return (
    <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
      <p className="font-bold text-4xl">Deposit and Withdraw</p>
      <div className="my-10">
        {content.map((item, index) => (
          <div key={index} className=" bg-white mt-5">
            <div className="bg-[#E0E0E0] font-bold text-xl p-3">{item.question}</div>
            <p className="p-6 text-[#34445B]">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqDepAndWit
