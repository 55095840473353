function Hero() {
  return (
    <div
      className="relative bg-cover bg-center bg-no-repeat py-48"
      style={{
        backgroundImage: "url('/images/landingpages/hero-bg.jpg')"
      }}>
      <img className="absolute top-12 md:left-0 -left-48 z-0" src="/images/landingpages/hero-2.svg" alt="" />

      <div className="mx-auto grid max-w-6xl grid-cols-12 gap-8 px-2 md:px-0">
        <div className="md:col-span-6 col-span-12 flex flex-col z-10">
          <div className="flex flex-col gap-5">
            <p className="text-6xl font-bold px-2">dCOPY.org</p>
            <p className="md:text-6xl text-4xl px-2 py-3 font-bold uppercase bg-[#FFC01D] text-black mt-2">Decentralized</p>
            <p className="md:text-6xl text-5xl font-bold px-2">Copy Trading</p>
          </div>
          <p className="mt-11 px-4 md:px-0">
            No time for tracking market fluctuation or no trading experience? Let Dcopy bring you the best investing solution - Copy Trade!
            Dcopy will enable you to trade like an expert and earn huge returns in easy way!
          </p>

          <a href={process.env.REACT_APP_DCOPY_URL}>
            <button className="px-4 py-3 bg-[#FFC01D] rounded-lg w-[250px] mt-10 font-bold text-black text-xl cursor-pointer">
              Connect dCOPY
            </button>
          </a>
        </div>

        <div className="col-span-6 hidden md:flex z-10">
          <img src="/images/landingpages/hero.png" alt="" />
        </div>
      </div>
      {/* <div className="flex mx-auto max-w-4xl rounded-t-md bg-white pt-11 mt-28 z-20">
        <div className="bg-[#FFC01D] text-center text-black font-bold py-1 w-full">Power by</div>
      </div> */}
      <img className="absolute md:-right-20 -right-64 bottom-0 z-0" src="/images/landingpages/hero-3.svg" alt="" />
    </div>
  )
}

export default Hero
