import React from "react"
import Layout from "../components/Layout"
import LandingPage from "../components/LandingPage"


function Landing() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <LandingPage />
      </Layout>
    </div>
  )
}

export default Landing
