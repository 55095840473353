const config = [
  {
    icon: '/images/landingpages/sec4-icon1.png',
    title: '1. Connect to Dcopy & Deposit fund',
    content: 'Allow to Dcopy access to your wallet by clicking [Connect] then deposit at least $10 to start trading'
  },
  {
    icon: '/images/landingpages/sec4-icon2.png',
    title: '2. Find and choose expert',
    content:
      'Discover the top traders then sort it by Profit, risk score, number of Autocopiers and more. Find the one for you and simply press Copy'
  },
  {
    icon: '/images/landingpages/sec4-icon3.png',
    title: '3. Set investment amount',
    content: 'Enter investment amount then set the values that limit your losses and lock in profits.'
  },
  {
    icon: '/images/landingpages/sec4-icon4.png',
    title: '4. Start Copying',
    content: "Press the Invest button to start automatically copying a investor's positions."
  }
]

function Section4() {
  return (
    <div className="py-32">
      <div className="mx-auto max-w-5xl px-4 md:px-0">
        <p className="max-w-xl text-5xl font-bold text-black">
          How to earn huge <br /> returns with Autocopy?
        </p>

        <div className="my-36 grid grid-cols-12 gap-5">
          {config.map((i, k) => (
            <div
              key={`sec4-${k}`}
              className="col-span-12 md:col-span-3 flex flex-col items-center rounded-lg bg-white py-11 px-6"
              style={{
                boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)'
              }}>
              <img className="h-[96px]" src={i.icon} alt="" />
              <p className="my-8 text-xl font-bold text-black">{i.title}</p>
              <p className="text-[#34445B]">{i.content}</p>
            </div>
          ))}
        </div>

        <div
          className="bg-cover bg-center bg-no-repeat flex flex-col items-center pt-28 pb-32 rounded-xl"
          style={{
            backgroundImage: 'url(/images/landingpages/sec4-bg.png)'
          }}>
          <p className="bg-[#FFC01D] text-center uppercase text-black italic font-bold text-4xl w-full">Ready to start trading with Dcoppy?</p>
          <p className="text-center text-xl my-8">Connect wallet and join the dCOPY family</p>
          <a href={`${process.env.REACT_APP_DCOPY_URL}`}>
          <button className="bg-[#FFC01D] px-6 py-3 rounded-md text-black font-bold">Join dCOPY</button>
          </a>
        </div>
      </div>
    </div>
  )
}
export default Section4
