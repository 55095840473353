import React from 'react'
// import LogoWithText from "./LogoWithText"

function Footer() {
  return (
    <div
      className="bg-[#090513]"
      style={{
        backgroundImage: 'url(/images/footer-bg.png)'
      }}>
      <div className="flex flex-col justify-between max-w-5xl px-2 py-10 mx-auto text-privacy md:px-0">
        <div className="flex justify-between">
          <div className="flex flex-col max-w-sm">
            {/* <LogoWithText /> */}
            <img src="/logo.svg" className="w-[150px]" />
            <p className="text-gray-300 mt-4 text-sm max-w-[190px] md:text-base md:max-w-sm">
              Dcopy desires to bring the trustworthy investing solution that enables traders to trade like experts and gain success in easy
              way!
            </p>
          </div>

          <div className="ml-2">
            <p className="font-bold md:text-2xl text-xl">Support</p>
            <div className="flex flex-col space-y-2 mt-4">
              <a href="/faqs">
                <p className="text-gray-300 text-sm md:text-base">FAQs</p>
              </a>
              <a href="">
                <p className="text-gray-300 text-sm md:text-base">Contact Us</p>
              </a>
              <a href="/teams">
                <p className="text-gray-300 text-sm md:text-base">Terms & Conditions</p>
              </a>
              <a href="/privacy">
                <p className="text-gray-300 text-sm md:text-base">Privacy Policy</p>
              </a>
            </div>
          </div>

          <div className="">
            <p className="font-bold md:text-2xl  text-xl">Fin us</p>
            <div className="flex flex-col space-y-2 mt-4 text-sm md:text-base">
              <a className="" href="https://twitter.com/dcopy_channel" target="_blank">
                <div className="flex space-x-2">
                  <img className='md:w-[24px] w-[15px]' src="/images/twitter.svg" />
                  <p>Twitter</p>
                </div>
              </a>
              <a href="https://youtube.com/channel/UCZlG1gDsXoqyLs9Pxz8_J_w" target="_blank">
                <div className="flex space-x-2">
                  <img className='md:w-[24px] w-[15px]' src="/images/youtube.svg" />
                  <p>Youtube</p>
                </div>
              </a>
              <a href="https://t.me/dcopy_Community" target="_blank">
                <div className="flex space-x-2">
                  <img className='md:w-[24px] w-[15px]' src="/images/telegram.svg" />
                  <p>Group</p>
                </div>
              </a>
              <a href="https://t.me/dcopy_Channel" target="_blank">
                <div className="flex space-x-2">
                  <img className='md:w-[24px] w-[15px]' src="/images/telegram.svg" />
                  <p>Channel</p>
                </div>
              </a>
            </div>
          </div>
        </div>

        {/* <div className="flex mt-10">
          <div className="flex flex-col">
            <p className="text-2xl font-bold text-white">PAGES</p>
          </div>

          <div className="mt-8">
            <p></p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Footer
