const content = [
  {
    question: '1. How to start Copy Expert',
    answer: (
      <div className="">
        <p>To mirror leading investor's trading order, you need to complete the following steps:</p>
        <ul className="list-decimal px-8">
          <li>Choose expert: Sort and find the trader that meets your requirements by performance, level, profit and more</li>
          <li>
            Set amount: Choose a total amount for the copy — the proportions are calculated automatically. Besides, you need to set the
            value that limits your loss and locks in profit
          </li>
          <li>Click Invest: Click the “Invest” button to start automatically copying the trader’s positions </li>
        </ul>
      </div>
    )
  },
  {
    question: '2. How to stop copy trade',
    answer: (
      <div className="">
        <p>Of course, you can stop Copy trade function anytime! To stop copying someone:</p>
        <ul className="list-decimal px-8">
          <li>Go to your Portfolio</li>
          <li>Click on the trader you wish to stop copying.</li>
          <li>Click on the icon three dot next to the selected trader.</li>
          <li>Select 'Stop copying' from the menu.</li>
          <li>On the new screen, click on 'Stop copying'.</li>
          <li>Select 'Confirm'.</li>
        </ul>
      </div>
    )
  },
  {
    question: '3. What is Pause copy?',
    answer: (
      <div className=" space-y-2">
        <p>‘Pause copy’ is a tool to stop copying a trader without closing all the positions that are currently open.</p>
        <p>
          After you click ‘Pause copy’, there will be no new trades opened in that copy relationship. However, all the existing copied
          trades will remain open. In addition, any changes to these positions made by the copied trader (such as closing them, or editing
          the Stop Loss or Take Profit) will still be copied.
        </p>
      </div>
    )
  },
  {
    question: '4. What is the minimum amount required to copy a trader?',
    answer:
      'The minimum amount required to copy a trader is $20. Please note that there is a minimum amount for each copied position of $1. Positions not meeting the $1 minimum will not be opened.'
  },
  {
    question: '5. Can I copy more than one investor?',
    answer: 'Yes, you can copy up to 100 different investors at any given time.'
  },
  {
    question: '6. Does it cost me to copy another trader?',
    answer: (
      <p>
        It will cost you <strong>5% investment</strong> for using Copy Trade service{' '}
      </p>
    )
  },
  {
    question: '7. What is AUC?',
    answer:
      'AUC (Assets Under Copy) is the total investment amount used to copy a trader, including unrealized profit and loss.Dcopy expert must have a certain amount of AUC in order to advance through the levels.'
  },
  {
    question: '8. How to become dCOPY expert? Which benefits will I get when being top traders?',
    answer:
      'To become expert, you need to contact us and send us register fund at $10,000. Then we will upgrade you to be expert of dCOPY! Each time your copiers invest for you, you will get 1% commission.'
  },
  {
    question: '9. How Dcopy ranks the level of expert?',
    answer: (
      <>
        <p>Dcopy ranks experts to 4 levels: Bronze, Sliver, Gold, Diamond Each level has its own requirements, you can refer below:</p>

        <ul className="list-decimal px-8">
          <li>
            <p>To enter Bronze level, trader must meet the following criteria:</p>
            <ul className="list-disc px-4">
              <li>Minimum average percentage of profit per day is 1%- 30%</li>
              <li>Total traders copying his positions reaches below 10</li>
              <li>Trader had an average AUC of at least $50 for the last month</li>
            </ul>
          </li>

          <li>
            <p>If trader meets the criteria below, he will move to Sliver level:</p>
            <ul className="list-disc px-4">
              <li>Traders have been at Bronze level for at least 1 months</li>
              <li>Minimum average percentage of profit per day is about 30% - 65%</li>
              <li>Total traders copying his positions reaches 10-50 people</li>
              <li>Trader had an average AUC of $50- $200 for the last month</li>
            </ul>
          </li>

          <li>
            <p>Trader will reach the Golden level, if he meets the criteria below:</p>
            <ul className="list-disc px-4">
              <li>Traders have been at Sliver level for at least 2 months</li>
              <li>Minimum average percentage of profit per day is 65% - 100%</li>
              <li>Total traders copying his positions reaches about 50- 200 people</li>
              <li>Trader had an average AUC of about $200 - $5000 for the last month</li>
            </ul>
          </li>

          <li>
            <p>The highest level- Diamond requires trader to reach the hardest milestones:</p>
            <ul className="list-disc px-4">
              <li>Traders have been at Diamond level for at least 3 months</li>
              <li>Minimum average percentage of profit per day is beyond 100%</li>
              <li>Total traders copying his positions reaches beyond 200 people</li>
              <li>Trader had an average AUC of at least $5000 for the last month</li>
            </ul>
          </li>
        </ul>
      </>
    )
  },
  {
    question: '10. How can the rate of new trades be calculated?',
    answer: (
      <>
        <p>
          New trades will open at the same rates as the copied trader opens them, and use the Realised equity as the basis for the
          proportions of copied trades. For example, a trade opened with 10% of the Copied Trader’s realised equity will open a trade in
          your copy account with 10% of the realised equity in the copy relationship.
        </p>
        <p className="italic text-sm mt-5">* Realised equity: balance - invested fund+ profit from closed position</p>
        <p className="italic text-sm">* Equity: balance - invested fund+ profit from both closed and opened position</p>
      </>
    )
  }
]

function FaqCopyTrade() {
  return (
    <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
      <p className="font-bold text-4xl">Copy Trade</p>
      <div className="my-10">
        {content.map((item, index) => (
          <div key={index} className=" bg-white mt-5">
            <div className="bg-[#E0E0E0] font-bold text-xl p-3">{item.question}</div>
            <p className="p-6 text-[#34445B]">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqCopyTrade
