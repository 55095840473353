import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import FaqCopyTrade from './LandingPage/FaqCopyTrade'
import FaqDepAndWit from './LandingPage/FaqDepAndWit'
import FaqMyAccount from './LandingPage/FaqMyAccount'
import FaqRef from './LandingPage/FaqRef'

function Faqs() {
  const { hash } = useLocation()
  
  const isCopyTrade = hash === '#copy-trade'
  const isDepAndWit = hash === '#deposit-and-withdraw'
  const isMyAccount = hash === '#my-account'
  const isRef = hash === '#referral'

  return (
    <div className="bg-[#F0F0F0] pb-20">
      <div className="bg-[#0E0E0E] md:pt-28">
        <div className="mx-auto flex flex-col md:flex-row max-w-5xl justify-between items-center gap-10">
          <p className="h-fit bg-[#FFC01D] text-5xl font-bold uppercase text-[#00070D] pr-10">FAQs</p>
          <img className="h-[314px]" src="/images/landingpages/faqs.png" alt="" />
        </div>
      </div>
      {hash === '' && (
        <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
          <p className="font-bold text-4xl">Topics</p>
          <div className="grid md:grid-cols-4 grid-cols-2 gap-6 my-20">
            <a href="/faqs#my-account">
              <button
                className=" bg-white rounded-lg w-full px-6 py-3 font-bold cursor-pointer"
                style={{
                  boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)'
                }}>
                My account
              </button>
            </a>
            <a href="/faqs#deposit-and-withdraw">
              <button
                className="bg-white rounded-lg w-full px-6 py-3 font-bold cursor-pointer"
                style={{
                  boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)'
                }}>
                Deposit and withdraw
              </button>
            </a>
            <a href="/faqs#copy-trade">
              <button
                className=" bg-white rounded-lg w-full px-6 py-3 font-bold cursor-pointer"
                style={{
                  boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)'
                }}>
                Copy trade
              </button>
            </a>
            <a href="/faqs#referral">
              <button
                className=" bg-white rounded-lg w-full px-6 py-3 font-bold cursor-pointer"
                style={{
                  boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)'
                }}>
                Referral
              </button>
            </a>
          </div>
        </div>
      )}
      {isMyAccount && <FaqMyAccount />}
      {isDepAndWit && <FaqDepAndWit />}
      {isCopyTrade && <FaqCopyTrade />}
      {isRef && <FaqRef />}
    </div>
  )
}
export default Faqs
