const commissionTable = [
  {
    name: 'F1',
    agencyCommission: '50',
    tradingCommission: '1'
  },
  {
    name: 'F2',
    agencyCommission: '25',
    tradingCommission: '0.5'
  },
  {
    name: 'F3',
    agencyCommission: '12.5',
    tradingCommission: '0.25'
  },
  {
    name: 'F4',
    agencyCommission: '6.25',
    tradingCommission: '0.125'
  },
  {
    name: 'F5',
    agencyCommission: '3.125',
    tradingCommission: '0.0625'
  },
  {
    name: 'F6',
    agencyCommission: '1.5625',
    tradingCommission: '0.03125'
  },
  {
    name: 'F7',
    agencyCommission: '0.78125',
    tradingCommission: '0.015625'
  }
]

const levels = [
  {
    name: 'Level 1',
    f1: '0',
    vol: '0'
  },
  {
    name: 'Level 2',
    f1: '3',
    vol: '2,000'
  },
  {
    name: 'Level 3',
    f1: '4',
    vol: '4,000'
  },
  {
    name: 'Level 4',
    f1: '5',
    vol: '8,000'
  },
  {
    name: 'Level 5',
    f1: '6',
    vol: '16,000'
  },
  {
    name: 'Level 6',
    f1: '7',
    vol: '32,000'
  },
  {
    name: 'Level 7',
    f1: '8',
    vol: '64,000'
  }
]

const content = [
  {
    question: '1. What is Agency License?',
    answer: (
      <p>
        Purchasing Agency License is an essential step to begin referring other users and developing your systems if you want to increase
        your revenues and earn passive income. Once you become an agency, you can earn trading commissions as well as agency commissions.
        Start now: <a href="https://app.dcopy.org/referral">https://app.dcopy.org/referral</a>
      </p>
    )
  },
  {
    question: '2. How to purchase Agency License and share my referral link?',
    answer: (
      <>
        <p>You can purchase Agency License by following steps below:</p>
        <ul className="list-decimal px-8">
          <li>Log in https://dcopy.org</li>
          <li>On the left menu bar, select Referral Page</li>
          <li>Click button [Pay Agency License $100]</li>
        </ul>

        <p className="mt-4">
          When you upgrade account to Agency successfully, we will suggest you 3 ways to refer your friend to join Dcopy:
        </p>
        <ul className="list-decimal px-8">
          <li>Copy and share your Referral link.</li>
          <li>Copy and share your Referral ID.</li>
          <li>Click on Social media share buttons for Facebook, Google+, LinkedIn, Twitter to share your referral link</li>
        </ul>
      </>
    )
  },
  {
    question: '3. Which benefits do we get when being partner with Dcopy?',
    answer: (
      <>
        <p>When being agency of Dcopy, you will earn both agency commission and investing commission from a 7-stage system:</p>
        <p className="italic font-bold mt-3 mb-2">1. Agency commission</p>
        <p>
          Each time your direct downline purchases Agency License, you will earn $50 immediately! The more the referral network you expand,
          the more commission you earn.
        </p>
        <p className="italic font-bold mt-3 mb-2">2. Investing commission</p>
        <p>Everytime your downline (F1) deposit fund to Dcopy, you will earn 1% of this investment.</p>
        <p>However, to earn sun commission from F2- F7, you need to upgrade your agency level.</p>
        <div className="mt-10">
          <table className="table-auto w-full">
            <thead>
              <tr className="grid grid-cols-3 text-center">
                <th className="border px-4 py-2"></th>
                <th className="border px-4 py-2">Agency commission</th>
                <th className="border px-4 py-2">Trading commission</th>
              </tr>
            </thead>
            <tbody>
              {commissionTable.map((item, index) => (
                <tr key={`commission-${index}`} className="grid grid-cols-3 text-center">
                  <td className="border px-4 py-2">{item.name}</td>
                  <td className="border px-4 py-2">${item.agencyCommission}</td>
                  <td className="border px-4 py-2">{item.tradingCommission}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  },
  {
    question: '4. What requirements will we meet to upgrade agency level?',
    answer: (
      <>
        <p>
          To earn sun commission from full level, you need to meet your strick requirements about the amount of F1 purchased agency license
          and weekly investment volume of F1
        </p>
        <div className="mt-10">
          <table className="table-auto w-full">
            <thead>
              <tr className="grid grid-cols-3 text-center">
                <th className="border px-4 py-2">Level</th>
                <th className="border px-4 py-2">F1 Agency</th>
                <th className="border px-4 py-2">F1’ volume investing (weekly)</th>
              </tr>
            </thead>
            <tbody>
              {levels.map((item, index) => (
                <tr key={`lv-${index}`} className="grid grid-cols-3 text-center">
                  <td className="border px-4 py-2">{item.name}</td>
                  <td className="border px-4 py-2">{item.f1}</td>
                  <td className="border px-4 py-2">${item.vol}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    )
  }
]

function FaqRef() {
  return (
    <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
      <p className="font-bold text-4xl">Referral</p>
      <div className="my-10">
        {content.map((item, index) => (
          <div key={index} className=" bg-white mt-5">
            <div className="bg-[#E0E0E0] font-bold text-xl p-3">{item.question}</div>
            <p className="p-6 text-[#34445B]">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FaqRef
