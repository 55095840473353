import Layout from '../components/Layout'

function Teams() {
  return (
    <div className="overflow-hidden">
      <Layout>
        <div className="">
          <div className="bg-[#0E0E0E] pt-28">
            <div className="mx-auto flex flex-col md:flex-row max-w-5xl items-center justify-between gap-10">
              <p className="h-fit bg-[#FFC01D] text-5xl font-bold uppercase text-[#00070D]">TERMS & CONDITIONS</p>
              <img className="h-[314px]" src="/images/privacy/risk.png" alt="" />
            </div>
          </div>

          <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
            <p>
              This notice provides you with information about the risks associated with investment products, in which you may invest,
              through services provided to you by Dcopy platform.
            </p>
            <br />
            <p className="text-xl font-bold text-black">1.Introduction</p>
            <p>
              1.1. By visiting Dcopy, you (&ldquo;Client&rdquo;) are accepting all the risks described in this Risk Disclosure Notice. Any
              reference to &lsquo;us&rsquo;, &lsquo;our&rsquo;, &lsquo;we&rsquo; or &lsquo;Dcopy&rsquo;, &lsquo;Company&rsquo; in this
              notice is a reference to each company within the Dcopy group of companies as the context requires unless otherwise stated.
              <br />
              1.2. You must read carefully this notice which is easily accessible on the Dcopy&rsquo;s website before deciding to open a
              trading account with us and before commencing any trading activity with this platform. Under no circumstances, should you risk
              more than you are prepared to lose.
            </p>
            <br />

            <p className="text-xl font-bold text-black">2.Scope of Application</p>
            <p>
              The derivatives transaction of digital assets is highly leveraged and risky, only suitable for professional investment
              institutions or experienced investors, or those who can fully understand all risks related to the derivatives transaction of
              digital assets and can assume some or all losses of account funds due to investment mistakes.
              <br />
              The Service is available only to users who are 18 years of age or older. By accessing or using the Platform's Services, you
              claim and guarantee that you are at least 18 years of age and have not been deprived of the right to use the Services. You
              also guarantee that you are not on any trade or economic sanctions list, such as the United Nations Security Council sanctions
              Lists, and are not restricted or prohibited from participating in any type of transaction by the European Union, the Hong Kong
              Monetary Authority, the Customs and Excise Department, the Office of Foreign Assets Control of the U.S. Department of the
              Treasury, and other administrative enforcement agencies.
            </p>
            <br />
            <p className="text-xl font-bold text-black">3. Trading risks</p>
            <p>
              Since Cryptocurrency markets are decentralized and non-regulated, our Cryptocurrencies Trading Services are unregulated
              services which are not governed by any specific European regulatory framework (including MIFID). This means that there is no
              central bank that can take corrective measures to protect the value of Cryptocurrencies in a crisis or issue more currency.
              Therefore, when Dcopy (Europe) customers use our Cryptocurrencies Trading Services, they will not benefit from the protections
              available to clients receiving regulated investment services such as access to the Investor Compensation Fund for Customers of
              Cypriot Investment Firms and the Financial Ombudsman Service for dispute resolution.
            </p>
            <br />
            <p className="text-xl font-bold text-black">4. Market risks</p>
            <p>
              a) Due to being determined by demand and supply only, The Cryptocurrency market is a dynamic arena and its respective prices
              are often highly unpredictable and volatile. <br />
              b) Besides, the market for cryptocurrency is still new and uncertain. No-one should have funds invested in cryptocurrency or
              speculate in cryptocurrency that she is not prepared to lose entirely. Whether the market for one or more cryptocurrency will
              move up or down, or whether a particular cryptocurrency will lose all or substantially all of its value, is unknown. This
              applies both to traders that are going long and to traders that are shorting the market. Participants should be cautious about
              holding cryptocurrency.
              <br />
              c) You acknowledge that Dcopy is not responsible for any losses or damages of any kind arising either directly or indirectly
              from the sending of cryptocurrency to the wrong wallet address during the withdrawal of funds from their Dcopy account. There
              is a risk that the cryptocurrency may unintentionally include weaknesses or bugs in the source code interfering with the use
              of or causing the loss of cryptocurrency.
            </p>
            <br />
            <p className="text-xl font-bold text-black">5. Copy trading risks</p>
            <p>
              Dcopy offers Social Trading Features. In making a decision to copy a specific trader or traders and/or follow a particular
              strategy, you must consider your entire financial situation, including financial commitments. You must understand that using
              Social Trading Features is highly speculative and that you could sustain significant losses exceeding the amount used to copy
              a trader or traders. The risks associated with Social Trading Features include, but are not limited to, automated trading
              execution whereby the opening and closing of trades will happen in your account without your manual intervention.
              <br />
              The copy trading function we provide is for reference only. The employees and agents of Dcopy and its affiliates are not
              investment or financial advisors. If you make an investment decision based on the information provided on our website or
              through the information obtained through the copy trading function, you do so at your own risk and Dcopy and its affiliates,
              its employees and agents do not bear any losses;
              <br />
              You should make your own investment decisions. You should independently determine whether your investment, strategy, or any
              other products and services meet your needs based on your investment objectives and personal and financial status;
              <br />
              If you make other transactions on your own or modify or cancel the instructions generated by the Copy Trading function, the
              assets of your account may be significantly different from the users you are copying. Withdrawals by the traders may also
              result in differences in the performance of the two accounts as these may affect the ratio of the order transactions;
              <br />
              Our past performance, risk profile, statistics and all information displayed on our website and applications about this user
              do not represent future performance and should be considered a hypothetical situation;
              <br />
              Past performance and risk ratio should be considered as theoretical performance. There are many inherent limitations to
              theoretical performance. It is not guaranteed that any account will obtain gains or losses similar to past performance or
              displayed risk rate. The actual income/loss percentage for each investor may vary, depending on a number of factors, including
              but not limited to: account balance (deposit and withdrawal) at the time of the transaction, market behavior, investor account
              setup, and the actual performance of the object.
            </p>
            <br />
            <p className="text-xl font-bold text-black">6. Prompt</p>
            <p>
              a) Investors must thoroughly understand the basic knowledge and related risks of digital currency contract transactions and
              the business rules related to participation in derivatives exchanges before participating.
              <br />
              b) The risk warnings mentioned above are only enumerative in nature. They fail to provide a detailed list of all risk factors
              related to digital asset derivatives trading. Investors should also seriously consider other possible risks before investing
              in digital asset derivatives trading. The factors are understood and mastered
              <br />
              c) The platform's sincere hopes and suggestions for investors, starting from their own actual conditions such as risk
              tolerance, prudently decide whether to participate in this contract transaction and reasonably configure their digital assets.
              <br />
              d) We respect all laws in all countries for blockchain cryptographic digital assets. The services shown on this page are not
              open to residents of the following countries: Afghanistan, Burundi, Belarus, Central African Republic, Congo, China Mainland,
              Hong Kong SAR, Macao SAR, Singapore, Malaysia, Ethiopia, Guinea, Guinea Bissau, Iraq, Iran, Democratic People's Republic of
              Korea, Lebanon, Sri Lanka, Libya, Serbia, Sudan, Somalia, South Sudan, Syrian Arab Republic, Thailand, Tunisia, Trinidad
              Tobago, Ukraine, Uganda, United States, Venezuela, Yemen, Zimbabwe, Cuba, United States (including all United States
              territories such as Puerto Rico, American Samoa, Guam). This list of excluded countries may vary and may vary by service.
            </p>
            <br />
            <p className="text-xl font-bold text-black">7. Changes to our Risk Disclosure Notice</p>
            <p>
              We may revise this Risk Disclosure from time to time. The most current version of the notice will always be available at
              Dcopy. We reserve the right to review and/or amend its Risk Disclosure Notice, at its sole discretion, whenever it deems
              necessary, without prior notice to you.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  )
}
export default Teams
