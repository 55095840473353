import IconBsc from '../icons/IconBsc'
import IconETH from '../icons/IconETH'
import IconPolygon from '../icons/IconPolygon'

const config = [
  {
    icon: '/images/landingpages/sec2-img1.png',
    title: 'Our Vision',
    content:
      'Being one of the world’s leading social investment platform connecting millions traders around the world that enables traders and investors to interact, share ideas, insights and trading strategies.'
  },
  {
    icon: '/images/landingpages/sec2-img2.png',
    title: 'Our Mission',
    content: 'To provides traders with the trustworthy financial tools that allows them to enhance the investing experience and wealth.'
  },
  {
    icon: '/images/landingpages/sec2-img3.png',
    title: 'Our Values',
    content:
      'Due to build on Blockchain, Dcoppy places great emphasis on transparency, security and openness. We strive to provide all usefull information our users need to become successful investors.'
  }
]

function Section2() {
  return (
    <div className="relative pb-44">


      <div className="max-w-5xl mx-auto grid grid-cols-12 md:gap-8 mt-48 gap-y-36 px-4 md:px-0">
        {config.map((item, index) => (
          <div
            key={`sec2-${index}`}
            className="relative col-span-12 md:col-span-4 flex flex-col items-center rounded-lg bg-white text-center px-6 pb-6"
            style={{
              boxShadow: '0px 2px 24px rgba(204, 204, 204, 0.25)',
            }}>
            <img className="absolute -top-28" src={item.icon} alt="" />
            <p className="text-3xl font-bold text-black mt-28">{item.title}</p>
            <p className="text-[#34445B] mt-8">{item.content}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default Section2
