function PrivacyPolicy() {
  return (
    <div className="">
      <div className="bg-[#0E0E0E] md:pt-28">
        <div className="mx-auto flex flex-col md:flex-row max-w-5xl justify-between items-center gap-10">
          <p className="h-fit bg-[#FFC01D] text-5xl font-bold uppercase text-[#00070D]">privacy policy</p>
          <img className="h-[314px]" src="/images/privacy/privacy.png" alt="" />
        </div>
      </div>
      <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
        <p>
          Your privacy is very important to Dcopy, and we&rsquo;re committed to protecting and respecting your personal data. This Privacy
          Policy describes the types of personal data that we collect about you when you choose to use our services, how we&rsquo;ll use
          your personal data and how we&rsquo;ll keep it safe. Please take the time to read this Privacy Policy carefully so that you can
          understand how we handle your personal data.
        </p>
        <p>
          By using our Websites or our Apps, applying for an account with us or giving us information, you&rsquo;re indicating that you
          understand how we collect, use and disclose your personal data in line with this Privacy Policy. If you don&rsquo;t agree with
          this Privacy Policy, you mustn&rsquo;t use our Website and our Apps, access our services or provide any information to us.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">1. Information we collect (receive) from you</p>
        <p>
          When you access or use our Services, we automatically collect information about you (the type of information depends on the Site
          you are using), including:
        </p>
        <ul
          style={{
            listStyleType: 'square',
            paddingLeft: '20px'
          }}>
          <li>
            Financial Information: We collect financial information related to your use of the Services, including information you provide
            to us or that we collect from public sources.
          </li>
          <li>
            Log Information: We collect log information about your use of the Services, including the type of browser you use, app version,
            access times, pages viewed, your IP address, any other network identifiers, and the page you visited before navigating to our
            Services
          </li>
          <li>
            Device Information: We collect information about the computer or mobile device you use to access our Services, including the
            hardware model, operating system and version, unique device identifiers, and mobile network information.
          </li>
          <li>
            Activities on the Site: We collect records of activities on the Site, including, any content you post, your Dcopy Account
            details, the time, value and currency of any deposit, withdrawal, or transaction hash made.
          </li>
          <li>
            Location Information: In accordance with your device permissions, we may collect information about the geo-location of your
            device. Information Collected by Cookies and Other Tracking Technologies: We use different technologies to collect information,
            including cookies and web beacons. You can find out more about Dcopy&rsquo;s use of cookies and similar technologies in our
            Cookie Policy.
          </li>
        </ul>

        <br />
        <p className="text-2xl font-bold text-black">2. How we collect your data</p>
        <ul
          style={{
            listStyleType: 'square',
            paddingLeft: '20px'
          }}>
          <li>
            <p className="italic">When You connect wallet to Dcopy for an Account or Services</p>
            <p>
              If you access Dcopy, we will collect basic information about you including IP address, wallet address, information relating to
              an individual&rsquo;s source of wealth. We will use the information that we collect about you to:
            </p>
            <p>● Sign in your Dcopy Account;</p>
            <p>● Contact you about your Dcopy Account and/or our Services (this may include marketing</p>
            <p>emails).</p>
            <p>
              Besides, when you use the online trading products we provide to you or perform transactions on Dcopy, we may gain datas
              related to your balance, wallet address, and the type of wallet you use to connect us.
            </p>
          </li>
          <li>
            <p className="italic">When you contact us</p>
            <p>
              If you contact us by telephone, email, post or use another function offered by Dcopy, we will collect any information about
              the communication and any additional information that you choose to give us. We will use this information to review,
              investigate and respond to any comment or question that you may raise. Please note that we record and retain all telephone
              calls and other communication with us and may use it in our dealings with you, including any dispute resolution or legal
              proceedings.
            </p>
            <p>When Dcopy users invite you to join Dcopy</p>
            <p>
              Users of Dcopy can invite their contacts to join Dcopy with them. We only collect the email addresses of individuals that
              users choose to invite to join Dcopy and Dcopy only uses the email addresses for sending an invitation to the individual at
              the request of the existing Dcopy user.
            </p>
          </li>
        </ul>

        <br />
        <p className="text-2xl font-bold text-black">3. How we may use your personal data</p>
        <p>
          We use the information we collect to provide, maintain, and improve the Services, such as to administer, verify and maintain your
          account, to provide the Services you request and customize your experience with us. We also use the information we collect to:
        </p>
        <p>a) carry out our obligations owed to you;</p>
        <p>b) comply with our regulatory and other legal obligations;</p>
        <p>c) administer transactions including deposits, chargebacks and payments;</p>
        <p>d) monitor/access any content you post;</p>
        <p>
          e) monitor trading activity on Dcopy, including by detecting inconsistencies in payments and trades and looking out for
          potentially illegal activities;
        </p>
        <p>f) compile statistical analysis of the pages of our platform and websites;</p>
        <p>g) monitor and analyze our business;</p>
        <p>
          h) investigate and to manage enquiries, disputes and legal proceedings and to comply with court orders, mandatory dispute
          resolution determinations and mandatory government authority or law enforcement orders or directions;
        </p>
        <br />
        <p className="text-2xl font-bold text-black">4. Disclosure of your information</p>
        <p>We will not share the personal information we hold about you except in the following circumstances:</p>
        <p>a) where required by law or a court order by a competent Court;</p>
        <p>b) to relevant authorities to investigate or prevent fraud, money laundering or other illegal activity;</p>
        <p>
          c) to other service providers who create, maintain or process databases (whether electronic or not), offer record keeping
          services, email transmission services, messaging services or similar services which aim to assist the Company to collect, storage,
          process and use Clients&rsquo; Information or get in touch with Client or improve the provision of the Company&rsquo;s Services or
          activities under the Agreement;
        </p>
        <br />
        <p className="text-2xl font-bold text-black">5. Safeguard Measures</p>
        <p>
          a) Your Information is stored on secure servers. We limit access to your Information only to those employees or partners that need
          to know the information to enable the carrying out of the Agreement.
        </p>
        <p>
          b) While the Company will use all reasonable efforts to safeguard Your Information, you acknowledges that the use of the internet
          is not entirely secure and for this reason the we cannot guarantee the security or integrity of any personal data transferred from
          you, or to you via the internet
        </p>
        <p>
          c) Website tracking systems may also collect data detailing the pagesthe you have accessed, how he discovered the our website, the
          frequency of his visits and any other action related thereof. The information we obtain is used to improve the content of our
          website(s) and may be used for contacting you.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">6. Your Right</p>
        <p>
          6.1. You may have certain rights and protections under the law regarding the processing of your personal data. For example, you
          may also have the right to object to, or request that we restrict, certain processing and in some circumstances to obtain a copy
          of the personal information in machine readable format. There are limits to such rights where they apply and in certain
          circumstances we may not be required or able to meet your request, or we may only meet your request in part.
        </p>
        <p>a) request access to your personal data (commonly known as a "data subject access request")</p>
        <p>b) request correction of the personal data that we hold about you;</p>
        <p>
          c) object to processing of your personal data if we&rsquo;re relying on a legitimate interest (or those of a third party) and you
          feel it impacts on your fundamental rights and freedoms. You also have the right to object if we&rsquo;re processing your personal
          data for direct marketing purposes. Please note that in some cases, we may prove that we&rsquo;ve got compelling legitimate
          grounds to process your information which override your rights and freedoms;
        </p>
        <p>d) ask us to suspend the processing of your personal data, if:</p>
        <p>if you want us to establish the data's accuracy;</p>
        <p>our use of the data is unlawful but you don&rsquo;t want us to erase it;</p>
        <p>
          you need us to hold the data even if we no longer require it, so that you can use it to establish, exercise or defend legal
          claims;
        </p>
        <p>you&rsquo;ve objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it;</p>
        <p>
          e) request the transfer of your personal data to you orto a third party. We&rsquo;ll provide you, or a third party that
          you&rsquo;ve chosen, with your personal data in a structured, commonly used, machine-readable format. Note that this right only
          applies to automated information (i.e. not to hard copies) which you initially consented to us using or where we used the
          information to perform a contract with you; and
        </p>
        <p>
          6.2. Please quote your name and wallet address when you write to us and provide brief details of the data that you would like a
          copy of or which you would like to be corrected (this helps us to locate your data more easily)
        </p>
        <p>
          6.3. We try to respond to all legitimate requests within 1 (one) week. It might take us longer than this if your request is
          particularly complex or if you&rsquo;ve made a number of requests. We&rsquo;ll let you know if this situation applies to you
          within 1 week of receiving your request and keep you updated.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">7. Where we store and process your personal data</p>
        <p>
          The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"),
          including places such as Australia, Singapore and the United States. It may also be processed by staff who work for us or one of
          our suppliers of Affiliate companies outside the EEA. Our staff outside of the EEA may be engaged in, among other things, the
          fulfillment of your request, the processing of your payment details and the provision of support services. By submitting your
          personal data to us, you agree to your personal data being transferred, stored and processed in this way. We&rsquo;ll take all
          steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">8. Retention of Personal Information</p>
        <p>
          We store the information we collect on you for as long as is necessary for the purpose(s) for which we originally collected it, or
          for other legitimate business purposes, including to meet our legal, regulatory, or other compliance obligations. The period for
          which we will retain personal information will vary depending on the purposes that it was collected for, as well as the
          requirements of any applicable law or regulation. For example, if you are invited to use Dcopy by another user, we will store the
          personal information about you provided by such other user for a period of six months in order to send an invitation to you by
          email.
        </p>
        <p>
          If you have an Dcopy Account, we will store your personal information for as long as you maintain that account, and after you
          cease holding an account, for as long as we are required to by laws and regulations that are applicable to us.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">9. Changes to this Privacy Policy</p>
        <p>
          The Policy will be reviewed and updated from time to time to take account of changes to our operations or practices and, further,
          to make sure it remains appropriate in light of any changes in law, technology and the business environment. Any personal
          information held will be governed by our most current Policy. Please refer back to the Policy on a regular basis.
        </p>
        <br />
        <p className="text-2xl font-bold text-black">10. Contact Us</p>
        <p>
          Should you have any questions regarding the Policy, please contact our customer support and open a ticket. Alternatively, you may
          also reach us at: contact@dcopy.org and please include your full name, and email address so we are able to handle your query
          properly. We may require that you provide additional information to verify your identity before allowing you to exercise certain
          rights outlined above.
        </p>
      </div>
    </div>
  )
}
export default PrivacyPolicy
