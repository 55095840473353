function Section3() {
  return (
    <div className="bg-[#0E0E0E] md:py-60 py-40">
      <div className="mx-auto grid max-w-5xl grid-cols-12 md:gap-x-12 gap-y-11 px-4 md:px-0">
        <img className="col-span-12 md:col-span-6" src="/images/landingpages/sec3-img.png" alt="" />
        <div className="col-span-12 md:col-span-6 flex flex-col justify-center">
          <p className="text-4xl font-bold">Mirror the trading</p>

          <div
            className="flex items-center text-center"
            style={
              {
                // lineHeight: '64px'
              }
            }>
            <p className="mr-1 text-4xl font-bold">way of</p>
            <img className="bg-[#FFC01D] py-2 px-4" src="/images/landingpages/toptraders.svg" alt="" />
          </div>
          <p className="mt-10">
            At Dcopy, each leading investor's performance is displayed transparently, including risk score, fail/success ratio and much
            more. Just select and mirror the operation of the best- performing one by Copy trade function: They success, you too!
          </p>
        </div>
      </div>
    </div>
  )
}
export default Section3
