import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import React from 'react'

// import MobileNav from "./MobileNav"

function MainNav() {
  // const { account } = useWeb3React()
  const [isOpen, setIsOpen] = React.useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset
      window.onscroll = function () {
        let currentScrollPos = window.pageYOffset
        if (currentScrollPos < 50) {
          //@ts-ignore
          document.getElementById('main-nav').style.top = '0'
        } else {
          let y = window.innerWidth > 768 ? '-100px' : '-500px'
          //@ts-ignore
          document.getElementById('main-nav').style.top = y
          setIsOpen(false)
        }
        prevScrollpos = currentScrollPos
      }
    }
  }, [])

  return (
    <div
      id="main-nav"
      className="fixed z-20 flex w-screen items-center justify-start px-2 md:px-4"
      style={{
        background: 'rgba(0, 0, 0, 0.35)'
      }}>
      <div className="mx-auto flex w-full max-w-6xl items-center justify-between">
        <div className="mr-8 flex items-center rounded-full">
          <Link to="/">
            <div className="cursor-pointer py-4 md:py-0">
              <img src="/logo.svg" width="150px" />
            </div>
          </Link>
        </div>

        <div className="items-center md:flex hidden">
          <Item href="/risk-disclaimer">Risk Disclaimer</Item>
          <Item href="/privacy">Privacy Policy</Item>
          <Item href="/teams">Terms of use</Item>

          <div className="my-4 w-fit">
            <a href={`${process.env.REACT_APP_DCOPY_URL}`}>
              <button className="bg-[#FFC01D] px-6 py-1 font-bold text-black duration-300 hover:scale-105 rounded-lg">Dcopy</button>
            </a>
          </div>
        </div>

        <div className="cursor-pointer md:hidden" onClick={() => setIsOpen(!isOpen)}>
          <IconMenu />
        </div>

        {isOpen && (
          <div className="absolute right-1 top-14 z-20 bg-black rounded-sm shadow-lg">
            <div className="flex flex-col">
              <Item href="/risk-disclaimer">Risk Disclaimer</Item>
              <Item href="/privacy">Privacy Policy</Item>
              <Item href="/teams">Team of use</Item>
              <a href={`${process.env.REACT_APP_DCOPY_URL}`}>
                <div className="my-4 flex px-4">
                  <button className="bg-[#FFC01D] w-full py-1 font-bold text-black duration-300 hover:scale-105 rounded-lg">Dcopy</button>
                </div>
              </a>
            </div>
          </div>
        )}
        {isOpen && <div className="fixed inset-0 bg-black opacity-50 z-10" onClick={() => setIsOpen(!isOpen)} />}
      </div>
    </div>
  )
}

export default MainNav

function Item({ children, href, ...props }: { children: React.ReactNode; href: string }) {
  const { pathname } = useLocation()
  const isActive = pathname === href

  return (
    <Link to={href || '/'}>
      <div className={`${!isActive ? 'border-b-0' : 'border-b-2'} mx-3 flex md:py-4 py-2`}>
        <p className={`${!isActive ? 'text-[#828282]' : 'font-bold text-white'} cursor-pointer uppercase`}>{children}</p>
      </div>
    </Link>
  )
}

export function IconMenu(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
      {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>
  )
}
