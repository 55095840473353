const content = [
  {
    question: '1. What do I need to start on Dcopy?',
    answer:
      'To start on Dcopy, you just need to connect your wallet to login our platform, deposit fund for trading then choose your suitable trader to begin copy trade.'
  },
  {
    question: '2. How can I contact Dcopy?',
    answer: 'You can send us a ticket via help center or write an email for us via address contact@dcopy.org'
  }
]

function MyAccount() {
  return (
    <div className="mx-auto my-20 max-w-5xl text-[#34445B] px-4 md:px-0">
      <p className="font-bold text-4xl">My Account</p>
      <div className="my-10">
        {content.map((item, index) => (
          <div key={index} className=" bg-white mt-5">
            <div className="bg-[#E0E0E0] font-bold text-xl p-3">{item.question}</div>
            <p className="p-6 text-[#34445B]">{item.answer}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MyAccount
